<template>
  <section id="topbar">
    <v-app-bar :class="user.dark?'':'main'" elevation="0" height="100" app>
      <v-toolbar rounded="lg" elevation="0">
        <v-app-bar-nav-icon @click="$emit('close')" />
        <v-spacer></v-spacer>
        <v-btn icon @click="mode">
          <v-icon>{{
            !user.dark ? "mdi-weather-night" : "mdi-weather-sunny"
          }}</v-icon>
        </v-btn>
      </v-toolbar>
    </v-app-bar>
  </section>
</template>

<script>
export default {
  props: ["user"],
  methods: {
    logout() {
      this.$store.dispatch("student/logoutAction");
    },
    mode() {
      this.$store.dispatch("student/theme");
      if (!this.user.dark) {
        this.$vuetify.theme.dark = true;
        this.$emit('mode')
        return;
      }
      this.$vuetify.theme.dark = false;
      this.$emit('mode')
    },
  },
};
</script>