<template>
  <v-main class="main" style="height: 100vh; overflow: hidden">
    <v-app-bar elevation="0" color="transparent">
      <v-spacer/>
      <v-btn text>Logout</v-btn>
    </v-app-bar>
    <v-container fill-height >
      <v-row justify="center" align="center">
        <v-col cols="12" lg="6">
          <div class="d-flex flex-column justify-start">
            <div class="display-1 text-center mb-5"><b>Change Password</b></div>
          </div>
          <v-card 
          color="transparent"
          max-width="350" 
          class="mx-auto" 
          elevation="0">
            <v-card-text>
              <v-form @submit.prevent="change" ref="pass">
                <label class="caption">NEW PASSWORD</label>
                <v-text-field solo 
                :append-icon="pass1?'mdi-eye-off-outline': 'mdi-eye-outline'"
                @click:append="pass1=!pass1"
                :error-messages="errors.password"
                v-model="form.password"
                :type="pass1?'text':'password'"/>
                <label class="caption">CONFIRM PASSWORD</label>
                <v-text-field solo 
                :append-icon="pass2?'mdi-eye-off-outline': 'mdi-eye-outline'"
                @click:append="pass2=!pass2"
                v-model="form.password_confirmation"
                :type="pass2?'text':'password'"/>
                <v-btn v-if="!loading" type="submit" block color="primary" large>Update</v-btn>
                <v-btn v-else type="submit" block color="primary" large>Updating....</v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapState } from 'vuex'
export default {
  props: ['user'],
  data: () => ({
    form: { password: '', password_confirmation: ''},
    pass1: false,
    pass2: false,
    loading: false,
  }),
  computed: { 
    ...mapState('student', {
      errors: (state) => state.errors
    })
  },
  methods: {
    change() {
      this.loading= true
      this.$store.dispatch('student/newPasswordAction',this.form)
      .then(() => {
        this.loading= false
        if(this.$errors('student')) {
          console.log('error uccured')
          return
        }
        this.$emit('success')
        this.$refs.pass.reset()
      })
    }
  } 
}
</script>