<template>
  <section id="main">
    <v-app v-if="!loading">
      <Topbar @close="drawer = !drawer" :user="user" @mode="mode"/>
      <Leftbar :drawer="drawer" :user="user" />
      <v-main :class="user.dark == 1 ? '' : 'main'" >
        <!-- <v-container class="ma-1"> -->
          <router-view class="ma-5"/>
        <!-- </v-container> -->
      </v-main>
    </v-app>
    <v-app v-else>
      <Password :user="user" v-if="user.status == 0" @success="fetch" />
      <v-container fill-height v-else>
        <v-row justify="center" align="center">
          <v-col cols="12" lg="4">
            <div class="d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="success"
              ></v-progress-circular>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </section>
</template>

<script>
import Topbar from "./Topbar.vue";
import Leftbar from "./Leftbar.vue";
import Password from "../components/main/NewPassword";
import { mapState } from "vuex";

export default {
  components: {
    Topbar,
    Leftbar,
    Password,
  },
  data: () => ({
    drawer: true,
    loading: true,
  }),
  computed: {
    ...mapState("student", {
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
    if(this.$route.name == 'Student Exhibit Material') return this.drawer= false

    this.drawer = true
  },
  methods: {
    fetch() {
      this.$store.dispatch("student/authAction").then(() => {
        if (!this.user.status == 0) {
          this.loading = false;
          this.$vuetify.theme.dark = true;
          if (!this.user.dark) {
            console.log("not dark");
            this.$vuetify.theme.dark = false;
          }
          return;
        }
      });
    },
    mode() {
      this.loading = true
 
      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  },
  watch: {
    $route(to) {
      console.log(to);
      if(this.$route.name == 'Student Exhibit Material') return this.drawer= false
      this.drawer = true
    },
  },
};
</script>