<template>
  <section id="leftbar">
    <v-navigation-drawer app v-model="drawer" class="drawer">
      <v-app-bar class="mt-5 d-flex justify-center" elevation="0" color="transparent">
        <div class="">
          <v-avatar tile size="120">
            <v-img :src="require('../../assets/images/logo.png')"></v-img>
          </v-avatar>
        </div>
      </v-app-bar>
      <div class="mt-10 mb-5 text-center">
        <div class="d-flex justify-center">
          <v-avatar v-if="user.image == null" color="primary" size="120"></v-avatar>
          <v-avatar v-else size="120">
            <v-img :src="`${user.aws}/users/${user.uuid}/image/${user.image.image}`"/>
          </v-avatar>
        </div>
        <div class="mt-2 font-weight-bold">{{user.firstname + ' ' + user.lastname}}</div>
        <div class="caption">USER</div>
      </div>
      <v-list class="leftbar">
        <div v-for="(item, i) in $studentRoutes" :key="i">
          <v-subheader class="caption">{{ item.subheader }}</v-subheader>
          <v-list-item
            v-for="(list, y) in item.list"
            :key="y"
            :to="{ name: list.route }"
            link
            exact
            :active-class="
              user.dark == 1
                ? 'leftbar__list--active-dark'
                : 'leftbar__list--active-light'
            "
          >
            <v-list-item-icon>
              <v-icon
                size="22"
                :class="
                  $route.name == list.route
                    ? ''
                    : 'leftbar__list font-weight-thin'
                "
              >
                {{ list.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :class="
                  $route.name == list.route
                    ? ''
                    : 'leftbar__list font-weight-thin'
                "
              >
                {{ list.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-item @click="logout" link>
          <v-list-item-icon>
            <v-icon size="22" class="leftbar__list">mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="leftbar__list">
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </section>
</template>

<script>
export default {
  props: ["drawer", "user"],
  methods: {
    logout() {
      this.$store.dispatch("student/logoutAction");
    },
  },
};
</script>